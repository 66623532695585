import {
  ArrowTopRightOnSquareIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { TemplateModal } from 'src/modules/messaging/whatsapp/components/organisms/templates/template-modal';
import Button from 'src/modules/shared/components/atoms/buttons/button';

const containerClasses = `
  flex-none flex overflow-hidden border-gray-200 h-16
  p-3 items-center justify-end space-x-2 bg-white
`;
const buttonClasses = 'rounded-lg p-2 flex items-center gap-1';
const iconClasses = 'w-5 h-5';

const ChatActionsHeader: React.FC = () => {
  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);

  return (
    <div className={containerClasses}>
      <Button
        onClick={() =>
          window.open(
            'https://business.facebook.com/latest/whatsapp_manager/message_templates',
            '_blank'
          )
        }
        size="xs"
        className={buttonClasses}
      >
        <ArrowTopRightOnSquareIcon className={iconClasses} />
        <span className="pt-0.5">Plantillas</span>
      </Button>

      <Button
        onClick={() => setIsTemplateModalOpen(true)}
        size="xs"
        className={buttonClasses}
      >
        {/* TODO: check when implementing i18n */}
        <PlusIcon className={iconClasses} />
      </Button>

      <TemplateModal
        isOpen={isTemplateModalOpen}
        onClose={() => setIsTemplateModalOpen(false)}
      />
    </div>
  );
};

export default ChatActionsHeader;

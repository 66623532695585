/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Type for body component type.
 */
export enum BodyComponentType {
    BODY = 'body',
}

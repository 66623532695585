import React from 'react';
import ViewContainer from 'components/atoms/view-container';
import { Chat } from 'src/modules/messaging/components/organisms/conversations/chat';

const ConversationsView: React.FC = () => {
  // TODO: check when implementing i18n
  return (
    <ViewContainer title="Conversaciones">
      <Chat />
    </ViewContainer>
  );
};

export default ConversationsView;

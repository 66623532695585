import React from 'react';
import Button from 'src/modules/shared/components/atoms/buttons/button';

interface ModalFooterProps {
  onCancel: () => void;
  onSend: () => void;
  sendDisabled: boolean;
  loading: boolean;
}

const ModalFooter: React.FC<ModalFooterProps> = ({
  onCancel,
  onSend,
  sendDisabled,
  loading,
}) => (
  <div className="flex justify-end space-x-2 rounded-lg shadow-sm p-4">
    <Button onClick={onCancel} variant="secondary" size="sm">
      {/* TODO: check when implementing i18n */}
      Cancelar
    </Button>
    <Button onClick={onSend} disabled={sendDisabled} size="sm">
      {/* TODO: check when implementing i18n */}
      {loading ? 'Enviando...' : 'Enviar mensaje'}
    </Button>
  </div>
);

export default ModalFooter;

import React from 'react';

interface TextAreaProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  variant?: 'primary' | 'secondary';
  size?: 'md' | 'sm' | 'xs';
  className?: string;
  disabled?: boolean;
}

const TextArea: React.FC<TextAreaProps> = ({
  value,
  onChange,
  onKeyDown,
  // TODO: check when implementing i18n
  placeholder = 'Escribe tu mensaje aquí...',
  variant = 'primary',
  size = 'md',
  className = '',
  disabled = false,
}) => {
  const baseClasses = [
    'flex-grow',
    'border',
    'rounded-md',
    'focus:outline-none',
    'focus:ring-1',
    'resize-none',
    'overflow-hidden',
    disabled ? 'bg-gray-100 cursor-not-allowed opacity-75' : '',
  ];

  const variantClasses = {
    primary: disabled ? '' : 'focus:ring-blue-500',
    secondary: disabled ? '' : 'focus:ring-gray-500',
  };

  const sizeClasses = {
    md: 'h-10 p-2 text-sm leading-tight',
    sm: 'h-8 p-1 text-sm leading-tight',
    xs: 'h-6 p-1 text-xs leading-tight',
  };

  const classes = [
    ...baseClasses,
    variantClasses[variant],
    sizeClasses[size],
    className,
  ].join(' ');

  return (
    <textarea
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
      className={classes}
      rows={1}
      disabled={disabled}
    />
  );
};

export default TextArea;

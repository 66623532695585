import React from 'react';
import { Conversation } from 'src/api/generated';
import { Card, CardBody, Typography } from '@material-tailwind/react';
import { isConversationExpired } from 'src/modules/messaging/utils/conversation.util';
import ConversationStatusDisplay from 'src/modules/messaging/components/atoms/conversations/conversation-status-display';

interface ConversationItemProps {
  conversation: Conversation;
  isSelected: boolean;
}

const ConversationItem: React.FC<ConversationItemProps> = ({
  conversation,
  isSelected = false,
}) => {
  const conversationExpired = isConversationExpired(conversation);

  const cardBaseClasses =
    'shadow-sm border rounded-none border-gray-100 hover:bg-gray-100 transition-colors duration-300 cursor-pointer';
  const cardBodyClasses = 'flex p-4 items-center';
  const contentContainerClasses = 'flex-1';
  const titleClasses = 'font-semibold';
  const timestampClasses = 'text-gray-500 text-sm';
  const statusContainerClasses = 'flex items-center gap-2 py-1';

  return (
    <Card className={`${cardBaseClasses} ${isSelected ? 'bg-gray-200' : ''}`}>
      <CardBody className={cardBodyClasses}>
        <div className={contentContainerClasses}>
          <Typography variant="h6" className={titleClasses}>
            {conversation.title}
          </Typography>
          <Typography className={timestampClasses}>
            {/* TODO: check when implementing i18n */}
            Último mensaje:{' '}
            {new Date(conversation.lastMessageAt).toLocaleString()}
          </Typography>
          <div className={statusContainerClasses}>
            <ConversationStatusDisplay status={conversation.status} />
            {conversationExpired && (
              <ConversationStatusDisplay status="expired" />
            )}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default ConversationItem;

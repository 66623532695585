import {
  ConversationStatus,
  Participant,
  ParticipantRole,
} from 'src/api/generated';

// TODO: check when implementing i18n
export const conversationStatusTranslations: Record<
  ConversationStatus | 'expired',
  string
> = {
  [ConversationStatus.OPEN]: 'Abierta',
  [ConversationStatus.CLOSED]: 'Cerrada',
  [ConversationStatus.ARCHIVED]: 'Archivada',
  expired: 'Expirada',
};

export const getContactAddress = (
  participants: Participant[]
): string | undefined => {
  const agent = participants.find(
    (participant) => participant.role === ParticipantRole.CONTACT
  );
  if (!agent) return undefined;

  return agent.address;
};

import React, { useState } from 'react';
import { Conversation } from 'src/api/generated';
import ConversationList from '../conversation-list';
import { ConversationDetail } from '../conversation-detail';
import ChatActionsHeader from 'src/modules/messaging/components/molecules/chat/chat-actions-header';

export const Chat: React.FC = () => {
  const [selectedConversation, setSelectedConversation] =
    useState<Conversation | null>(null);

  return (
    <div className="flex h-full border-y border-gray-200 bg-white">
      <div className="w-1/3 h-full flex flex-col border-r border-gray-200">
        <ChatActionsHeader />
        <div className="flex-1 overflow-y-auto bg-gray-100 shadow-inner">
          <ConversationList
            onSelectConversation={setSelectedConversation}
            selectedConversationId={selectedConversation?.id}
          />
        </div>
      </div>

      <div className="w-2/3 h-full flex flex-col">
        {selectedConversation ? (
          <ConversationDetail
            conversation={selectedConversation}
            onUpdateConversation={setSelectedConversation}
          />
        ) : (
          <div className="flex items-center justify-center h-full bg-gray-50">
            <p className="text-gray-500 text-lg">
              {/* TODO: check when implementing i18n */}
              Selecciona una conversación para empezar a chatear
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

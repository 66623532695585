import Loading from 'components/atoms/loading/loading';
import React, { ComponentProps, ReactNode } from 'react';

interface SelectOption {
  content: ReactNode | string;
  value: string;
}

interface SelectProps extends Omit<ComponentProps<'select'>, 'size'> {
  options: SelectOption[];
  variant?: 'primary' | 'secondary';
  size?: 'md' | 'sm' | 'xs';
  loading?: boolean;
  className?: string;
  containerClassName?: string;
}

const Select: React.FC<SelectProps> = ({
  options,
  variant = 'primary',
  size = 'md',
  loading = false,
  className = '',
  containerClassName = '',
  ...rest
}) => {
  const baseClasses = [
    'flex-1',
    'flex',
    'border',
    'rounded-md',
    'focus:outline-none',
    'focus:ring-1',
    'min-w-[140px]',
  ];

  const variantClasses = {
    primary: 'focus:ring-blue-500 border-gray-300',
    secondary: 'focus:ring-gray-500 border-gray-400',
  };

  const sizeClasses = {
    md: 'py-2 px-4 text-md',
    sm: 'py-1 px-3 text-sm',
    xs: 'py-1 px-2 text-xs',
  };

  const classes = [
    ...baseClasses,
    variantClasses[variant],
    sizeClasses[size],
    className,
  ].join(' ');

  const containerClasses = [
    'flex',
    'justify-end',
    'items-center',
    containerClassName,
  ].join(' ');

  return (
    <div className={containerClasses}>
      <select className={classes} disabled={loading} {...rest}>
        {options.map(({ content, value }) => (
          <option key={value} value={value}>
            {content}
          </option>
        ))}
      </select>
      {loading && <Loading className="ml-2" />}
    </div>
  );
};

export default Select;

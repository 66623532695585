import React, { ReactNode } from 'react';
import Label from '../label';
import Select from '../select';

interface LabelSelectProps {
  label: string;
  options: { content: ReactNode | string; value: string }[];
  variant?: 'primary' | 'secondary';
  size?: 'md' | 'sm' | 'xs';
  className?: string;
  selectProps?: Omit<React.ComponentProps<typeof Select>, 'options'>;
}

const LabelSelect: React.FC<LabelSelectProps> = ({
  label,
  options,
  variant = 'primary',
  size = 'md',
  className = '',
  selectProps = {},
}) => {
  return (
    <div
      className={`flex flex-col sm:flex-row sm:items-center justify-between gap-2 ${className}`}
    >
      <Label variant={variant} size={size}>
        {label}
      </Label>
      <Select
        options={options}
        variant={variant}
        size={size}
        {...selectProps}
      />
    </div>
  );
};

export default LabelSelect;

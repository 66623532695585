import React, { useState } from 'react';
import { Conversation, ConversationStatus } from 'src/api/generated';
import { MessageList } from '../message-list';
import { useParticipants } from 'src/modules/messaging/hooks/api/participants';
import ConversationDetailsModal from '../../../molecules/conversations/conversation-details-modal';
import { useUpdateConversation } from 'src/modules/messaging/hooks/api/conversations';
import Select from 'src/modules/shared/components/atoms/inputs/select';
import { Typography } from '@material-tailwind/react';
import { conversationStatusTranslations } from 'src/modules/messaging/utils/participant.util';

interface ConversationDetailProps {
  conversation: Conversation;
  onUpdateConversation: (conversation: Conversation) => void;
}

const statusOptions = Object.values(ConversationStatus).map((status) => ({
  content: conversationStatusTranslations[status],
  value: status,
}));

export const ConversationDetail: React.FC<ConversationDetailProps> = ({
  conversation,
  onUpdateConversation,
}) => {
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const { participants, loading, error } = useParticipants({
    conversationId: conversation.id,
  });

  const { updateConversation, loading: updateLoading } = useUpdateConversation({
    onUpdateSuccess: (updatedConversation) => {
      onUpdateConversation(updatedConversation);
    },
    onUpdateError: (error) => {
      console.error('Error updating conversation:', error);
    },
  });

  const handleStatusChange = (newStatus: string) => {
    updateConversation({
      conversationId: conversation.id,
      status: newStatus as ConversationStatus,
    });
  };

  return (
    <>
      <div className="flex-none h-16 p-0 border-b items-center flex justify-between">
        <Typography
          variant="h5"
          className="flex-1 h-full px-4 cursor-pointer hover:bg-gray-50 flex items-center"
          onClick={() => setIsDetailsModalOpen(true)}
        >
          {conversation.title}
        </Typography>
        <Select
          options={statusOptions}
          value={conversation.status}
          onChange={(e) => handleStatusChange(e.target.value)}
          size="md"
          containerClassName="pr-4"
          loading={updateLoading}
        />
      </div>

      <MessageList conversation={conversation} participants={participants} />

      <ConversationDetailsModal
        isOpen={isDetailsModalOpen}
        onClose={() => setIsDetailsModalOpen(false)}
        conversation={conversation}
        participants={participants}
        loading={loading}
        error={error}
      />
    </>
  );
};

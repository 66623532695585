import React from 'react';
import { Button } from '@material-tailwind/react';
import Loading from 'components/atoms/loading/loading';
import { Conversation } from 'src/api/generated';
import { useConversations } from 'src/modules/messaging/hooks/api/conversations';
import ConversationItem from '../../../molecules/conversations/conversation-item';

interface ConversationListProps {
  onSelectConversation: (conversation: Conversation) => void;
  selectedConversationId?: string;
}

const ConversationList: React.FC<ConversationListProps> = ({
  onSelectConversation,
  selectedConversationId,
}) => {
  const { conversations, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useConversations({});

  return (
    <>
      {conversations.map((conversation) => (
        <div
          key={conversation.id}
          onClick={() => onSelectConversation(conversation)}
        >
          <ConversationItem
            conversation={conversation}
            isSelected={conversation.id === selectedConversationId}
          />
        </div>
      ))}
      {hasNextPage && (
        <Button
          onClick={() => fetchNextPage()}
          disabled={isFetchingNextPage}
          className="w-full py-2 bg-blue-500 text-white hover:bg-blue-600"
        >
          {isFetchingNextPage ? <Loading /> : 'Load More'}
        </Button>
      )}
    </>
  );
};

export default ConversationList;

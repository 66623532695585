import SidebarButton from 'components/atoms/buttons/sidebar-button';
import {
  BookOpenIcon,
  ChartBarIcon,
  ChatBubbleLeftRightIcon,
  Cog6ToothIcon,
  FunnelIcon,
  PhoneIcon,
  StarIcon,
  UsersIcon,
} from '@heroicons/react/24/solid';
import { SettingName } from 'src/api/generated';
import { Route as ReportsIndexRoute } from 'src/routes/reports.index';
import { Route as CallsIndexRoute } from 'src/routes/calls.index';
import { Route as ConversationsIndexRoute } from 'src/routes/conversations.index';
import { Route as ContactsIndexRoute } from 'src/routes/contacts.index';
import { Route as FeaturedCallsIndexRoute } from 'src/routes/featured-calls.index';
import { Route as OpportunitiesIndexRoute } from 'src/routes/opportunities.index';
import { Route as UsersIndexRoute } from 'src/routes/users.index';
import { Route as SettingsIndexRoute } from 'src/routes/settings.index';
import SidebarLogoutButton from '../buttons/sidebar-logout-button';
import { useFeatureFlagActive } from 'hooks/settings/feature-flags';
import { useBooleanSetting } from 'hooks/settings/settings';
import Loading from 'components/atoms/loading/loading';

const Sidebar = () => {
  const crmActive = useFeatureFlagActive(SettingName.FEATURE_FLAG_CRM);
  const conversationsActive = useFeatureFlagActive(
    SettingName.FEATURE_FLAG_CONVERSATIONS
  );
  const { setting: showReports, loading } = useBooleanSetting(
    SettingName.SHOW_REPORTS
  );

  return (
    <div className="bg-blue-600 h-full min-h-screen p-4 text-white flex flex-col justify-between overflow-y-auto">
      <div>
        <h2 className="font-bold text-xl mt-4 mb-8 mx-2">LlamadaPro</h2>
        {loading ? (
          <Loading className="py-6 w-[153px]" />
        ) : (
          <nav className="space-y-4">
            {/* TODO: check when implementing i18n */}
            {showReports && (
              <SidebarButton
                to={ReportsIndexRoute.to}
                icon={<ChartBarIcon className="h-6 w-6" />}
                label="Reportes"
              />
            )}
            <SidebarButton
              to={CallsIndexRoute.to}
              icon={<PhoneIcon className="h-6 w-6" />}
              label="Llamadas"
            />
            <SidebarButton
              to={ConversationsIndexRoute.to}
              icon={<ChatBubbleLeftRightIcon className="h-6 w-6" />}
              label="Conversaciones"
              hidden={!conversationsActive}
            />
            <SidebarButton
              to={OpportunitiesIndexRoute.to}
              icon={<FunnelIcon className="h-6 w-6" />}
              label="Seguimiento"
              hidden={!crmActive}
            />
            <SidebarButton
              to={ContactsIndexRoute.to}
              icon={<BookOpenIcon className="h-6 w-6" />}
              label="Prospectos"
              hidden={crmActive}
            />
            <SidebarButton
              to={UsersIndexRoute.to}
              icon={<UsersIcon className="h-6 w-6" />}
              label="Usuarios"
            />
            <SidebarButton
              to={FeaturedCallsIndexRoute.to}
              icon={<StarIcon className="h-6 w-6" />}
              label="Destacadas"
            />
            <SidebarButton
              to={ContactsIndexRoute.to}
              icon={<BookOpenIcon className="h-6 w-6" />}
              label="Contactos"
              hidden={!crmActive}
            />
            <SidebarButton
              to={SettingsIndexRoute.to}
              icon={<Cog6ToothIcon className="h-6 w-6" />}
              label="Ajustes"
              hidden={!conversationsActive}
            />
          </nav>
        )}
      </div>
      <div className="my-14">
        <SidebarLogoutButton />
      </div>
    </div>
  );
};

export default Sidebar;

import React from 'react';
import Label from '../label';
import TextInput from '../text-input';

interface LabelTextInputProps {
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  variant?: 'primary' | 'secondary';
  size?: 'md' | 'sm' | 'xs';
  className?: string;
  labelProps?: React.ComponentProps<typeof Label>;
  inputProps?: Omit<
    React.ComponentProps<typeof TextInput>,
    'value' | 'onChange'
  >;
}

const LabelTextInput: React.FC<LabelTextInputProps> = ({
  label,
  value,
  onChange,
  variant = 'primary',
  size = 'md',
  className = '',
  labelProps = {},
  inputProps = {},
}) => {
  return (
    <div
      className={`flex flex-col sm:flex-row sm:items-center justify-between gap-2 ${className}`}
    >
      <Label variant={variant} size={size} {...labelProps}>
        {label}
      </Label>
      <TextInput
        value={value}
        onChange={onChange}
        variant={variant}
        size={size}
        {...inputProps}
      />
    </div>
  );
};

export default LabelTextInput;

import React, { ComponentProps } from 'react';

interface TextInputProps extends Omit<ComponentProps<'input'>, 'size'> {
  variant?: 'primary' | 'secondary';
  size?: 'md' | 'sm' | 'xs';
  className?: string;
}

const TextInput: React.FC<TextInputProps> = ({
  variant = 'primary',
  size = 'md',
  className = '',
  ...rest
}) => {
  const baseClasses = [
    'flex-1',
    'border',
    'rounded-md',
    'focus:outline-none',
    'focus:ring-1',
    'max-w-full',
  ];

  const variantClasses = {
    primary: 'focus:ring-blue-500 border-gray-300',
    secondary: 'focus:ring-gray-500 border-gray-400',
  };

  const sizeClasses = {
    md: 'py-2 px-4 text-md',
    sm: 'py-1 px-3 text-sm',
    xs: 'py-1 px-2 text-xs',
  };

  const classes = [
    ...baseClasses,
    variantClasses[variant],
    sizeClasses[size],
    className,
  ].join(' ');

  return <input type="text" className={classes} {...rest} />;
};

export default TextInput;

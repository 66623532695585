import React, { useState } from 'react';
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from '@material-tailwind/react';
import ViewContainer from 'components/atoms/view-container';
import WhatsAppSignup from 'components/organisms/integrations/facebook/whatsapp-signup';

const SettingsView: React.FC = () => {
  const [open, setOpen] = useState<number | null>(null);

  const handleOpen = (section: number) => {
    setOpen(open === section ? null : section);
  };

  // TODO: check when implementing i18n
  return (
    <ViewContainer title="Ajustes">
      <h2 className="text-xl mb-4">Integraciones</h2>

      <div className="space-y-4">
        <Accordion open={open === 2} className="border rounded-md">
          <AccordionHeader onClick={() => handleOpen(2)} className="p-4">
            WhatsApp
          </AccordionHeader>
          <AccordionBody className="p-4">
            <p className="mt-2 mb-4">
              Para comenzar a utilizar WhatsApp debes conectar tu cuenta de Meta
              y entregar a LlamadaPro los permisos necesarios.
            </p>
            <WhatsAppSignup />
          </AccordionBody>
        </Accordion>
      </div>
    </ViewContainer>
  );
};

export default SettingsView;

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Type for header component type.
 */
export enum HeaderComponentType {
    HEADER = 'header',
}

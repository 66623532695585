import React from 'react';
import {
  CheckCircleIcon,
  XCircleIcon,
  ArchiveBoxIcon,
  ClockIcon,
} from '@heroicons/react/24/outline';
import { ConversationStatus } from 'src/api/generated';
import { conversationStatusTranslations } from 'src/modules/messaging/utils/participant.util';

interface ConversationStatusDisplayProps {
  status: ConversationStatus | 'expired';
  size?: 'sm' | 'md' | 'lg' | 'xl';
  className?: string;
  textClassName?: string;
}

const getIconClasses = (size: 'sm' | 'md' | 'lg' | 'xl' = 'md') => {
  const sizeClasses = {
    sm: 'w-3 h-3',
    md: 'w-4 h-4',
    lg: 'w-5 h-5',
    xl: 'w-6 h-6',
  };
  return sizeClasses[size];
};

const statusIconClasses = {
  [ConversationStatus.OPEN]: `${getIconClasses()} text-green-500`,
  [ConversationStatus.CLOSED]: `${getIconClasses()} text-red-500`,
  [ConversationStatus.ARCHIVED]: `${getIconClasses()} text-gray-500`,
  expired: `${getIconClasses()} text-yellow-500`,
};

const getStatusIcon = (
  status: ConversationStatus | 'expired',
  size: 'sm' | 'md' | 'lg' | 'xl'
) => {
  const iconClass = getIconClasses(size);
  switch (status) {
    case ConversationStatus.OPEN:
      return (
        <CheckCircleIcon
          className={`${iconClass} ${statusIconClasses[status]}`}
        />
      );
    case ConversationStatus.CLOSED:
      return (
        <XCircleIcon className={`${iconClass} ${statusIconClasses[status]}`} />
      );
    case ConversationStatus.ARCHIVED:
      return (
        <ArchiveBoxIcon
          className={`${iconClass} ${statusIconClasses[status]}`}
        />
      );
    case 'expired':
      return (
        <ClockIcon className={`${iconClass} ${statusIconClasses.expired}`} />
      );
    default:
      return null;
  }
};

const itemClasses = 'flex items-center text-gray-400';
const textClasses = (
  size: 'sm' | 'md' | 'lg' | 'xl',
  textClassName?: string
) => {
  const textSizeClasses = {
    sm: 'text-xs',
    md: 'text-sm',
    lg: 'text-base',
    xl: 'text-lg',
  };
  return `${textSizeClasses[size]} ml-1 ${textClassName || ''}`;
};

const ConversationStatusDisplay: React.FC<ConversationStatusDisplayProps> = ({
  status,
  size = 'md',
  className,
  textClassName,
}) => (
  <div className={`${itemClasses} ${className}`}>
    {getStatusIcon(status, size)}
    <span className={textClasses(size, textClassName)}>
      {conversationStatusTranslations[status]}
    </span>
  </div>
);

export default ConversationStatusDisplay;

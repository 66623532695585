import React, { ComponentProps } from 'react';

type LabelProps = ComponentProps<'label'> & {
  className?: string;
  variant?: 'primary' | 'secondary';
  size?: 'md' | 'sm' | 'xs';
};

const Label: React.FC<LabelProps> = ({
  children,
  className = '',
  variant = 'primary',
  size = 'md',
  ...rest
}) => {
  const baseClasses = ['flex-1', 'font-medium', 'block'];

  const variantClasses = {
    primary: 'text-gray-700',
    secondary: 'text-gray-500',
  };

  const sizeClasses = {
    md: 'py-2 px-4 text-md',
    sm: 'py-1 px-3 text-sm',
    xs: 'py-1 px-2 text-xs',
  };

  const classes = [
    ...baseClasses,
    variantClasses[variant],
    sizeClasses[size],
    className,
  ].join(' ');

  return (
    <label className={classes} {...rest}>
      {children}
    </label>
  );
};

export default Label;

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Type for footer component type.
 */
export enum FooterComponentType {
    FOOTER = 'FOOTER',
}

import React, { useState } from 'react';
import TextArea from 'src/modules/shared/components/atoms/inputs/text-area';
import Button from 'src/modules/shared/components/atoms/buttons/button';

interface MessageInputProps {
  onSendMessage: (message: string) => void;
  isSendingMessage: boolean;
  disabled?: boolean;
}

export const MessageInput: React.FC<MessageInputProps> = ({
  onSendMessage,
  isSendingMessage,
  disabled = false,
}) => {
  const [newMessage, setNewMessage] = useState('');

  const handleSendMessage = () => {
    if (newMessage.trim()) {
      onSendMessage(newMessage);
      setNewMessage('');
    }
  };

  return (
    <>
      <TextArea
        value={newMessage}
        onChange={(e) => setNewMessage(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage();
          }
        }}
        size="md"
        className="align-bottom"
        disabled={disabled}
      />
      <Button
        onClick={handleSendMessage}
        size="xs"
        disabled={disabled || isSendingMessage}
      >
        {/* TODO: check when implementing i18n */}
        {isSendingMessage ? 'Enviando...' : 'Enviar'}
      </Button>
    </>
  );
};

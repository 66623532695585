import React from 'react';
import {
  replacePlaceholders,
  splitMessageByLines,
} from 'src/modules/messaging/whatsapp/util/template.util';
import PlaceholderInputs from './placeholder-inputs';

interface MessagePreviewProps {
  templateText: string;
  placeholderValues: string[];
  onPlaceholderChange: (values: string[]) => void;
}

const REPLACER_PATTERN = '%%';

const renderPlaceholder = (placeholderContent: string, key: number) => (
  <span key={key} className="bg-yellow-100 px-1 rounded text-gray-600">
    {placeholderContent}
  </span>
);

const renderMessageLine = (line: string, lineIndex: number) => {
  const parts = line.split(
    new RegExp(`(${REPLACER_PATTERN}.*?${REPLACER_PATTERN})`, 'g')
  );
  return (
    <React.Fragment key={lineIndex}>
      {parts.map((part, key) => {
        const isPlaceholder =
          part.startsWith(REPLACER_PATTERN) && part.endsWith(REPLACER_PATTERN);
        if (!isPlaceholder) {
          return (
            <span key={key} className="text-gray-600">
              {part}
            </span>
          );
        }

        const placeholderContent = part.slice(
          REPLACER_PATTERN.length,
          -REPLACER_PATTERN.length
        );
        return renderPlaceholder(placeholderContent, key);
      })}
      <br />
    </React.Fragment>
  );
};

const MessagePreview: React.FC<MessagePreviewProps> = ({
  templateText,
  placeholderValues,
  onPlaceholderChange,
}) => {
  const finalMessage = replacePlaceholders(
    templateText,
    placeholderValues,
    REPLACER_PATTERN
  );
  const messageLines = splitMessageByLines(finalMessage);

  return (
    <div className="space-y-4">
      {/* TODO: check when implementing i18n */}
      <h2 className="text-lg font-medium text-gray-700">
        Vista previa del mensaje
      </h2>
      <PlaceholderInputs
        templateText={templateText}
        placeholderValues={placeholderValues}
        onPlaceholderChange={onPlaceholderChange}
      />
      <div className="py-4 px-8 bg-gray-100 rounded-lg shadow-sm">
        {messageLines.map((line, lineIndex) =>
          renderMessageLine(line, lineIndex)
        )}
      </div>
    </div>
  );
};

export default MessagePreview;

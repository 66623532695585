import { Conversation } from 'src/api/generated';

export const getConversationExpiration = (
  conversation: Conversation
): Date | undefined => {
  const expirationTime: string | undefined =
    conversation.integrationsFields?.expirationTime;
  if (!expirationTime) return undefined;

  return new Date(expirationTime);
};

export const isConversationExpired = (conversation: Conversation): boolean => {
  const expirationDate = getConversationExpiration(conversation);
  if (!expirationDate) return false;

  const now = new Date();
  return expirationDate <= now;
};

import React, { useState } from 'react';
import { Button } from '@material-tailwind/react';
import { WhatsAppSessionInfo } from 'hooks/integrations/facebook/types';
import {
  useInitFacebookSDK,
  useSetPostMessageListener,
  useWhatsAppSignup,
} from 'hooks/integrations/facebook';
import { useCreateWhatsAppIntegration } from 'src/modules/messaging/whatsapp/hooks/api/integrations';
import { WhatsAppIntegrationCreateBodyParams as WhatsAppIntegrationCreateParams } from 'src/api/generated';

const WhatsAppSignup: React.FC = () => {
  const [sessionInfo, setSessionInfo] = useState<WhatsAppSessionInfo | null>(
    null
  );

  useInitFacebookSDK();
  useSetPostMessageListener(setSessionInfo);

  const { loginResponse, launchWhatsAppSignup } = useWhatsAppSignup();

  const fbButtonClasses = `
    bg-[#1877f2] border-0 rounded-md text-white 
    font-sans font-bold text-xs px-4 py-3
  `;

  const { loading, error, integration, createWhatsAppIntegration } =
    useCreateWhatsAppIntegration();

  const sendIntegration = async () => {
    if (!sessionInfo) return;

    const code = loginResponse?.authResponse?.code;
    if (!code) return;

    const newWhatsAppIntegration: WhatsAppIntegrationCreateParams = {
      phoneNumberId: sessionInfo?.phone_number_id || 'phone_number_2_id',
      wabaId: sessionInfo?.waba_id || 'phone_number_id',
      code: code || 'phone_number_id',
    };
    createWhatsAppIntegration(newWhatsAppIntegration);
  };

  return (
    <div>
      <Button onClick={launchWhatsAppSignup} className={fbButtonClasses}>
        Conectar con Facebook
      </Button>
      <Button onClick={() => sendIntegration()} className={fbButtonClasses}>
        Crear integración
      </Button>

      {/* Display session info and SDK response for debugging */}
      {sessionInfo && (
        <div>
          <h3>Session Info:</h3>
          <pre>{JSON.stringify(sessionInfo, null, 2)}</pre>
        </div>
      )}
      {loginResponse && (
        <div>
          <h3>SDK Response:</h3>
          <pre>{JSON.stringify(loginResponse, null, 2)}</pre>
        </div>
      )}
      <div>
        <h3>Integration Info:</h3>
        <pre>{loading}</pre>
        <pre>{JSON.stringify(error, null, 2)}</pre>
        <pre>{JSON.stringify(integration, null, 2)}</pre>
      </div>
    </div>
  );
};

export default WhatsAppSignup;

import React from 'react';
import LabelTextInput from 'src/modules/shared/components/atoms/inputs/label-text-input';

interface PlaceholderInputsProps {
  templateText: string;
  placeholderValues: string[];
  onPlaceholderChange: (values: string[]) => void;
}

const PlaceholderInputs: React.FC<PlaceholderInputsProps> = ({
  templateText,
  placeholderValues,
  onPlaceholderChange,
}) => {
  const placeholderMatches = templateText.match(/{{\d+}}/g) || [];

  const handleChange = (index: number, value: string) => {
    const newValues = [...placeholderValues];
    newValues[index] = value;
    onPlaceholderChange(newValues);
  };

  return (
    <div className="space-y-4">
      {placeholderMatches.map((placeholder, index) => (
        <LabelTextInput
          key={index}
          label={`Ingresa un valor para ${placeholder}`}
          value={placeholderValues[index] || ''}
          onChange={(e) => handleChange(index, e.target.value)}
          variant="primary"
          size="sm"
        />
      ))}
    </div>
  );
};

export default PlaceholderInputs;

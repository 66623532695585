import React, { useEffect } from 'react';
import { useWhatsAppTemplates } from 'src/modules/messaging/whatsapp/hooks/api/templates';
import { useCreateWhatsAppTemplateMessage } from 'src/modules/messaging/whatsapp/hooks/api/messages';
import Loading from 'components/atoms/loading/loading';
import InfoError from 'components/atoms/info-error';
import TemplateSelect from '../../../molecules/templates/template-select';
import TemplateInfo from '../../../molecules/templates/template-info';
import MessagePreview from '../../../molecules/templates/message-preview';
import ModalFooter from '../../../molecules/templates/modal-footer';
import { useTemplateMessage } from 'src/modules/messaging/whatsapp/hooks/templates';
import { usePlaceholderValues } from 'src/modules/messaging/whatsapp/hooks/templates';
import IntegrationPhoneSelect from '../../../molecules/phones/integration-phone-select';
import {
  usePhoneNumberInput,
  useSelectedPhone,
} from 'src/modules/messaging/whatsapp/hooks/phones';
import LabelTextInput from 'src/modules/shared/components/atoms/inputs/label-text-input';
import { useAlert } from 'src/contexts/alert-context';
import Modal from 'src/modules/shared/components/molecules/modals/modal';
import { Typography } from '@material-tailwind/react';
import { WhatsAppTemplateMessageVariable } from 'src/api/generated';

interface TemplateModalProps {
  isOpen: boolean;
  onClose: () => void;
  fromPhoneNumberId?: string;
  toNumber?: string;
}

export const TemplateModal: React.FC<TemplateModalProps> = ({
  isOpen,
  onClose,
  fromPhoneNumberId,
  toNumber,
}) => {
  const { showAlert } = useAlert();

  const {
    phoneNumber: selectedToNumber,
    isValid: isValidNumber,
    handleChange: handleNumberChange,
    setPhoneNumber,
  } = usePhoneNumberInput({ initialNumber: toNumber || '' });

  useEffect(() => {
    if (!isOpen) return;

    setPhoneNumber(toNumber || '');
  }, [isOpen, toNumber, setPhoneNumber]);

  const {
    phones,
    loading: phonesLoading,
    error: phonesError,
    selectedPhone,
    handlePhoneChange,
  } = useSelectedPhone({ initialPhoneId: fromPhoneNumberId });

  const {
    templates,
    loading: templatesLoading,
    error: templatesError,
  } = useWhatsAppTemplates({
    integrationProviderId: selectedPhone?.integrationProviderId,
  });

  const { selectedTemplate, messageContent, selectTemplate } =
    useTemplateMessage(templates);
  const { placeholderValues, setPlaceholderValues } =
    usePlaceholderValues(messageContent);

  const { createWhatsAppTemplateMessage, loading: createMessageLoading } =
    useCreateWhatsAppTemplateMessage(
      () => {
        showAlert('Mensaje enviado correctamente.', 'success');
        onClose();
      },
      (error: Error) => {
        console.error('Failed to send message', error);
        showAlert(
          `Ocurrió un error enviando el mensaje. Por favor revisa tu conexión e intenta nuevamente.`,
          'error'
        );
      }
    );

  const handleSendTemplateMessage = () => {
    const recipientNumber = toNumber || selectedToNumber;
    if (
      !selectedPhone ||
      !recipientNumber ||
      !selectedTemplate ||
      !isValidNumber
    ) {
      return;
    }

    const variables: WhatsAppTemplateMessageVariable[] = placeholderValues.map(
      (value) => ({ value })
    );

    createWhatsAppTemplateMessage({
      fromPhoneNumber: selectedPhone.address,
      toPhoneNumber: recipientNumber,
      templateName: selectedTemplate.name,
      variables,
      languageCode: selectedTemplate.language,
    });
  };

  return (
    // TODO: check when implementing i18n
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={
        <Typography variant="h4" className="text-gray-800">
          Nuevo mensaje
        </Typography>
      }
      body={
        <div className="flex flex-col flex-1 space-y-6">
          {phonesLoading && <Loading />}
          {phonesError && <InfoError error={phonesError} />}
          {!phonesLoading && !phonesError && (
            <IntegrationPhoneSelect
              phones={phones}
              selectedPhoneId={selectedPhone?.providerId}
              onPhoneChange={handlePhoneChange}
            />
          )}
          <LabelTextInput
            // TODO: check when implementing i18n
            label="Destinatario"
            value={selectedToNumber}
            onChange={handleNumberChange}
            size="sm"
            inputProps={{
              placeholder: '+1234567890',
              className: isValidNumber
                ? ''
                : 'border-red-500 focus:border-red-500 focus:ring-red-500',
            }}
          />
          {templatesLoading && <Loading />}
          {templatesError && <InfoError error={templatesError} />}
          {!templatesLoading && !templatesError && (
            <>
              <TemplateSelect
                templates={templates}
                selectedTemplateId={selectedTemplate?.id}
                onTemplateChange={selectTemplate}
              />

              {selectedTemplate && (
                <>
                  <TemplateInfo template={selectedTemplate} />
                  <MessagePreview
                    templateText={messageContent}
                    placeholderValues={placeholderValues}
                    onPlaceholderChange={setPlaceholderValues}
                  />
                </>
              )}
            </>
          )}
        </div>
      }
      footer={
        <ModalFooter
          onCancel={onClose}
          onSend={handleSendTemplateMessage}
          sendDisabled={
            !selectedTemplate ||
            placeholderValues.includes('') ||
            createMessageLoading ||
            !isValidNumber
          }
          loading={createMessageLoading}
        />
      }
    />
  );
};

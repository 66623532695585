import { QueryFilters } from '@tanstack/react-query';
import { IModel, UpdateListCacheParams } from 'src/types/cache';

interface InfiniteQueryData<TModel> {
  pages: { items: TModel[] }[];
  pageParams: unknown[];
}

/**
 * Updates the list cache with the provided updated data.
 *
 * @param queryClient - The query client instance used to manage the cache.
 * @param queryKey - The key used to identify the query in the cache.
 * @param updatedData - The data that needs to be updated in the cache.
 */
export const updateListCache = <TModel extends IModel>({
  queryClient,
  queryKey,
  updatedData,
}: UpdateListCacheParams<TModel>) => {
  const filters: QueryFilters = {
    queryKey,
    exact: false,
  };

  queryClient.setQueriesData<InfiniteQueryData<TModel> | TModel[] | undefined>(
    filters,
    (oldData) => {
      if (!oldData) return oldData;

      if ('pages' in oldData) {
        return updateInfiniteQueryCache<TModel>(
          oldData as InfiniteQueryData<TModel>,
          updatedData
        );
      }

      if (Array.isArray(oldData)) {
        return updateRegularQueryCache<TModel>(oldData, updatedData);
      }

      return oldData;
    }
  );
};

/**
 * Updates the infinite query cache with the provided updated data.
 *
 * @param oldData - The existing data in the cache.
 * @param updatedData - The data that needs to be updated in the cache.
 * @returns The updated infinite query data.
 */
function updateInfiniteQueryCache<TModel extends IModel>(
  oldData: InfiniteQueryData<TModel>,
  updatedData: TModel
) {
  const newPages = oldData.pages.map((page) => {
    if (!Array.isArray(page.items)) {
      return page;
    }

    const index = page.items.findIndex((item) => item.id === updatedData.id);
    if (index !== -1) {
      const newItems = [...page.items];
      newItems[index] = updatedData;
      return { ...page, items: newItems };
    }
    return page;
  });

  const itemExists = oldData.pages.some(
    (page) =>
      Array.isArray(page.items) &&
      page.items.some((item) => item.id === updatedData.id)
  );

  if (!itemExists && Array.isArray(newPages[0].items)) {
    newPages[0].items = [...newPages[0].items, updatedData];
  }

  return { ...oldData, pages: newPages };
}

/**
 * Updates the regular query cache with the provided updated data.
 *
 * @param oldData - The existing data in the cache.
 * @param updatedData - The data that needs to be updated in the cache.
 * @returns The updated regular query data.
 */
function updateRegularQueryCache<TModel extends IModel>(
  oldData: TModel[] | undefined,
  updatedData: TModel
) {
  if (!oldData) return [updatedData];

  const index = oldData.findIndex((item) => item.id === updatedData.id);
  const newData = [...oldData];

  if (index !== -1) {
    newData[index] = updatedData;
  } else {
    newData.push(updatedData);
  }

  return newData;
}

import { useEffect, useCallback, useRef, RefObject, useState } from 'react';

interface UseInfiniteScrollParams {
  containerRef: RefObject<HTMLDivElement>;
  isFetching: boolean;
  hasMore: boolean;
  fetchMore: () => void;
}

export const useInfiniteScroll = ({
  containerRef,
  isFetching,
  hasMore,
  fetchMore,
}: UseInfiniteScrollParams) => {
  const prevScrollHeightRef = useRef<number>(0);

  const handleScroll = useCallback(() => {
    const container = containerRef.current;
    if (!container) return;

    if (container.scrollTop <= 0 && hasMore && !isFetching) {
      prevScrollHeightRef.current = container.scrollHeight;
      fetchMore();
    }
  }, [containerRef, fetchMore, hasMore, isFetching]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    container.addEventListener('scroll', handleScroll);
    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, [containerRef, handleScroll]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    if (isFetching || !container) return;

    const prevScrollHeight = prevScrollHeightRef.current;
    if (!prevScrollHeight) return;

    const newScrollHeight = container.scrollHeight;
    container.scrollTop = newScrollHeight - prevScrollHeight;
    prevScrollHeightRef.current = 0;
  }, [containerRef, isFetching]);
};

interface UseScrollPositionReturn {
  isAtBottom: boolean;
  handleScroll: (event: React.UIEvent<HTMLDivElement>) => void;
}

export function useScrollPosition(): UseScrollPositionReturn {
  const [isAtBottom, setIsAtBottom] = useState(true);

  const handleScroll = useCallback((event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    const atBottom = scrollTop + clientHeight >= scrollHeight - 50;
    setIsAtBottom(atBottom);
  }, []);

  return { isAtBottom, handleScroll };
}

interface UseScrollToBottomParams {
  bottomAnchorRef: RefObject<HTMLDivElement>;
  isAtBottom: boolean;
  dependency: unknown[];
}

interface UseScrollToBottomReturn {
  scrollToBottom: () => void;
}

export function useScrollToBottom({
  bottomAnchorRef,
  isAtBottom,
  dependency,
}: UseScrollToBottomParams): UseScrollToBottomReturn {
  const isInitialMount = useRef(true);

  const scrollToBottom = useCallback(
    (behavior: ScrollBehavior = 'smooth') => {
      if (!bottomAnchorRef.current) return;

      bottomAnchorRef.current.scrollIntoView({ behavior });
    },
    [bottomAnchorRef]
  );

  useEffect(() => {
    if (!isAtBottom || dependency.length === 0) return;

    if (isInitialMount.current) {
      scrollToBottom('auto');
      isInitialMount.current = false;
    } else {
      scrollToBottom();
    }
  }, [dependency, isAtBottom, scrollToBottom]);

  return { scrollToBottom };
}

import React, { ComponentProps } from 'react';

type ButtonProps = ComponentProps<'button'> & {
  className?: string;
  variant?: 'primary' | 'secondary' | 'danger' | 'warning' | 'plain';
  size?: 'md' | 'sm' | 'xs';
};

const Button: React.FC<ButtonProps> = ({
  children,
  className = '',
  variant = 'primary',
  size = 'md',
  disabled = false,
  ...rest
}) => {
  const baseClasses = [
    'flex',
    'h-fit',
    'whitespace-nowrap',
    'font-semibold',
    'border',
    'rounded-md',
    'transition-colors',
    'duration-200',
    'justify-center',
    'items-center',
    disabled ? 'opacity-50 cursor-not-allowed' : '',
  ];

  const variantClasses = {
    primary: 'bg-blue-600 text-white border-transparent',
    secondary: 'bg-white text-blue-600 border-blue-600',
    danger: 'bg-red-600 text-white border-transparent',
    warning: 'bg-yellow-500 text-white border-transparent',
    plain: 'bg-white text-gray-700 border-gray-300',
  };

  const hoverClasses = {
    primary: 'hover:bg-blue-700',
    secondary: 'hover:bg-gray-100',
    danger: 'hover:bg-red-700',
    warning: 'hover:bg-yellow-600',
    plain: 'hover:bg-gray-100',
  };

  const activeClasses = {
    primary: 'active:bg-blue-800',
    secondary: 'active:bg-gray-200',
    danger: 'active:bg-red-800',
    warning: 'active:bg-yellow-700',
    plain: 'active:bg-gray-200',
  };

  const sizeClasses = {
    md: 'py-3 px-6 text-md',
    sm: 'py-2 px-4 text-sm',
    xs: 'py-1 px-2 text-xs',
  };

  const classes = [
    ...baseClasses,
    variantClasses[variant],
    !disabled && hoverClasses[variant],
    !disabled && activeClasses[variant],
    sizeClasses[size],
    className,
  ].join(' ');

  return (
    <button className={classes} disabled={disabled} {...rest}>
      {children}
    </button>
  );
};

export default Button;
